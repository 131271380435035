import React, { Fragment } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  AlternateButton,
  DarkModeSecondaryButton,
} from './KpButton.styles';
import { ButtonTypes } from '@utils/Constant';
import { ButtonWrapper } from './KpButton.styles';

interface KPButtonProps {
  buttonType:
    | typeof ButtonTypes.Primary
    | typeof ButtonTypes.Secondary
    | typeof ButtonTypes.Alternate;
  color: string | string[];
  textColor?: string;
  type?: string;
  loading?: boolean;
  disabled?: boolean;
  link?: string;
  id: string;
  children: object | string;
  fullWidth?: string;
  ignoreMinWidth?: boolean;
  onClick?: () => void;
  darkTheme?: boolean;
  style?: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const KpButton = ({
  buttonType,
  textColor,
  color,
  link,
  children,
  onClick,
  fullWidth,
  ignoreMinWidth,
  id,
  style,
  darkTheme,
  ...otherProps
}: KPButtonProps) => {
  const ignoreMW = ignoreMinWidth ? ignoreMinWidth : false;
  let mobileFullWidth = 'auto';
  let tabletFullWidth = 'auto';
  let desktopFullWidth = 'auto';
  if (fullWidth) {
    const devices = fullWidth.split(' ');
    if (devices.find((d) => d === 'mobile') !== undefined) {
      mobileFullWidth = '100%';
    }
    if (devices.find((d) => d === 'tablet') !== undefined) {
      tabletFullWidth = '100%';
    }
    if (devices.find((d) => d === 'computer') !== undefined) {
      desktopFullWidth = '100%';
    }
  }
  const buttonLink =
    link !== undefined && link !== ''
      ? link.includes('?')
        ? link
        : link.endsWith('/')
          ? link
          : link + '/'
      : '';
  return (
    <ButtonWrapper
      mobileFullWidth={mobileFullWidth}
      tabletFullWidth={tabletFullWidth}
      desktopFullWidth={desktopFullWidth}
      url={buttonLink}
      id={id}
    >
      <Fragment>
        {buttonType === ButtonTypes.Primary ? (
          <PrimaryButton
            mobileFullWidth={mobileFullWidth}
            tabletFullWidth={tabletFullWidth}
            desktopFullWidth={desktopFullWidth}
            textColor={textColor}
            color={color}
            ignoreMinWidth={ignoreMW}
            onClick={onClick}
            id={id}
            darkTheme={darkTheme}
            {...otherProps}
          >
            {children}
          </PrimaryButton>
        ) : buttonType === ButtonTypes.Secondary ? (
          <SecondaryButton
            mobileFullWidth={mobileFullWidth}
            tabletFullWidth={tabletFullWidth}
            desktopFullWidth={desktopFullWidth}
            textColor={textColor}
            color={color}
            ignoreMinWidth={ignoreMW}
            onClick={onClick}
            id={id}
            style={style}
            darkTheme={darkTheme}
            {...otherProps}
          >
            {children}
          </SecondaryButton>
        ) : buttonType === ButtonTypes.Alternate ? (
          <AlternateButton
            color={color}
            onClick={onClick}
            id={id}
            {...otherProps}
          >
            {children}
          </AlternateButton>
        ) : buttonType === ButtonTypes.DarkModeSecondary ? (
          <DarkModeSecondaryButton
            mobileFullWidth={mobileFullWidth}
            tabletFullWidth={tabletFullWidth}
            desktopFullWidth={desktopFullWidth}
            textColor={textColor}
            color={color}
            ignoreMinWidth={ignoreMW}
            onClick={onClick}
            id={id}
            {...otherProps}
          >
            {children}
          </DarkModeSecondaryButton>
        ) : null}
      </Fragment>
    </ButtonWrapper>
  );
};

export default KpButton;
